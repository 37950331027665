import React, { useEffect, useState } from 'react';

import { useCopyrightDate } from 'App/Game/hooks';
import LazyImg from 'App/React/_UI/LazyImg';

import logo from '../../../../assets/footer_logo.svg';
import upButton from '../../../../assets/up-arrow.svg';
import polyglot from '../../../../localization';
import settings from '../../../../localization/settings.json';
import './styles.scss';

const getTermsAndPrivacyLinkHref = (type, language, path) => {
    const localizedSetting = settings[language];
    const langSubfolder =
        localizedSetting.role === 'main' ? '' : `/${language}/`;

    return path + langSubfolder + (localizedSetting[type]?.fileName || null);
};

const Footer = ({ showTermsUse, lang, path, fetchLocal }) => {
    const [termsAndPrivacyLinks, setTermsAndPrivacyLinks] = useState({
        terms: '#',
        privacy: '#',
    });
    const currentYear = useCopyrightDate();

    useEffect(() => {
        if (fetchLocal) {
            const termsLink = getTermsAndPrivacyLinkHref('terms', lang, path);
            const privacyLink = getTermsAndPrivacyLinkHref(
                'privacy',
                lang,
                path
            );

            setTermsAndPrivacyLinks({ terms: termsLink, privacy: privacyLink });
        }
    }, []);

    return (
        <footer className='footer'>
            <div className='footer_main wrapper'>
                <div className='footer_main-wrapper container'>
                    <div className='footer_about'>
                        <h3 className='footer_about__title'>
                            {polyglot.t(`${lang}.footer.main.title`)}
                        </h3>
                        <p className='footer_about__text'>
                            {polyglot.t(`${lang}.footer.main.aboutText`)}
                        </p>
                        <p className='footer_about__highlight'>
                            {polyglot.t(`${lang}.footer.main.haveFun`)}
                        </p>
                    </div>
                    <div className='footer_terms'>
                        <div className='footer_logo'>
                            <LazyImg
                                url={logo}
                                width={113}
                                height={90}
                                alt={polyglot.t(`${lang}.footer.alt.logo`)}
                            />
                        </div>
                        <div>
                            <img
                                src={upButton}
                                className='scroll-button'
                                onClick={() => {
                                    window.scrollTo({
                                        top: 0,
                                        behavior: 'smooth',
                                    });
                                }}
                                alt='up button'
                                loading='lazy'
                            />
                            <p className='footer_terms-title'>
                                {polyglot.t(`${lang}.footer.main.termsTitle`)}
                            </p>
                            <div className='footer_terms-links'>
                                <a
                                    href={termsAndPrivacyLinks.terms}
                                    onClick={(e) => showTermsUse(e, 'terms')}>
                                    {polyglot.t(
                                        `${lang}.footer.main.termLinkText`
                                    )}
                                </a>
                                &nbsp;|&nbsp;
                                <a
                                    href={termsAndPrivacyLinks.privacy}
                                    onClick={(e) => showTermsUse(e, 'privacy')}>
                                    {polyglot.t(
                                        `${lang}.footer.main.policyLinkText`
                                    )}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer_copyright wrapper' id='footer_visible'>
                <div className='container'>
                    <p>
                        ©{currentYear}
                        {polyglot.t(`${lang}.footer.copyrightText`)}
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
