export const getGameDefaults = (config) => {
    const { buildVersion, buildPath, domain } = config;

    return {
        BuildVersion: buildVersion,
        AddressablesSettingsPath: `${buildPath}${buildVersion}`,
        AddressablesBundlePath: `${domain}/bundle/${buildVersion}/WebGL`,
        OfferConfiguration: {
            PWAState: 'NotSupported',
            PushState: 'Denied',
            DisplayConfiguration: {
                DefaultOfferType: 2,
                DisplayFrequency: 0,
                DisplayCount: 0,
                OfferPlacement: 2,
                OfferLevelDisplay: '1-3',
            },
        },
        GaCurrencies: 'Key,Coin',
        GaItemTypes:
            'ADS,BuySkin,CompleteLevel,Cheat,MultiplyPanel,OpenChest,Offer',
    };
};
