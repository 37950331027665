import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';

import * as Sentry from '@sentry/react';
import {
    getUrlParams,
    getDeviceRam,
    getGameInitValues,
    filterGameRelatedAttributes,
} from 'App/Game/helpers';
import { usePageLoad } from 'App/Game/hooks';
import useBotDetector from 'App/Game/useBotDetector';
import {
    getHash,
    environment,
    checkAdBlockers,
    getUrlParamValue,
    isProd,
} from 'App/Helpers';
import initReactSentry from 'App/Helpers/initReactSentry';
import CookieService from 'App/Services/CookieService';

import { getAdConfig } from '../AdService/helpers';
import { adDefaultMap, gaCustomDimensions } from '../config';

import { LanguageContext } from './LanguageProvider';

const phaseMap = {
    1: 'landing',
    2: 'push',
    3: 'pwa',
    4: 'game',
};

const AppContext = createContext(null);

const AppProvider = ({ appConfig, children }) => {
    const {
        source,
        domain,
        buildPath,
        gaTrackId,
        pushCampaignConfig,
        cookiesDomain,
        sharpstarApiUrl,
        gameAnalyticsKey,
        gameAnalyticsSecret,
        activeAdProviders,
        adsConfig: adsSettings,
    } = appConfig;
    const [urlParams, setUrlParams] = useState({
        rid: undefined,
        refid1: undefined,
        refid2: undefined,
        refid3: undefined,
        hash: undefined,
    });
    const { locale } = useContext(LanguageContext);
    const [phase, setPhase] = useState(''); // 'landing','push','pwa','game'
    const [adBlocked, setAdBlocked] = useState(undefined);
    const [sharpstarService, setSharpstarService] = useState(undefined);
    const [leadExtraData, setLeadExtraData] = useState(undefined);
    const [offer, setOffer] = useState(undefined);
    const [analyticsConfig, setAnalyticsConfig] = useState(undefined);
    const [gameGA, setGameGA] = useState(undefined);
    const [ga, setGA] = useState(undefined);
    const [gameConfig, setGameConfig] = useState(undefined);
    const [adsConfig, setAdsConfig] = useState(undefined);
    const [playFabId, setPlayFabId] = useState(undefined);

    const isPageLoaded = usePageLoad();
    const botInfo = useBotDetector();

    useEffect(() => {
        handleUrlParams(pushCampaignConfig?.[locale]?.rid);

        const hashChangedHandler = () => {
            const urlHashValue = getHash();

            if (phaseMap[urlHashValue]) {
                updatePhase(phaseMap[urlHashValue]);
            }
        };

        window.addEventListener('hashchange', hashChangedHandler);

        return () =>
            window.removeEventListener('hashchange', hashChangedHandler);
    }, []);

    const initializeSharpstar = useCallback(async () => {
        const { default: SharpstarServiceHandler } = await import(
            'App/Services/Handlers/SharpstarServiceHandler'
        );

        return new SharpstarServiceHandler(
            sharpstarApiUrl,
            source,
            pushCampaignConfig?.[locale]?.campaignId,
            new CookieService(cookiesDomain)
        );
    }, [sharpstarApiUrl, source, pushCampaignConfig.locale, cookiesDomain]);

    const initializeGameGA = useCallback(
        async (key, secret, buildVersion, dimensions, resources) => {
            const { default: GameAnalyticsService } = await import(
                'App/Services/Analytics/GameAnalytics'
            );

            return new GameAnalyticsService({
                gameKey: key,
                gameSecret: secret,
                environment: environment(),
                build: buildVersion,
                dimensions: dimensions,
                resources: resources,
            });
        },
        []
    );

    const initializeGA = useCallback(async () => {
        const { default: GoogleAnalytics } = await import(
            'App/Services/Analytics/GA4'
        );

        return new GoogleAnalytics(
            true,
            source,
            gaTrackId,
            {},
            { debug_mode: environment() !== 'production' }
        );
    }, [gaTrackId, source]);

    useEffect(() => {
        if (isPageLoaded) {
            initReactSentry({
                campaignId: pushCampaignConfig?.[locale]?.campaignId,
                location: document.location.host,
                environment: environment(),
                refid3: urlParams.refid3,
                source,
            });
        }
    }, [isPageLoaded]);

    useEffect(() => {
        if (isPageLoaded && botInfo.visitorId !== null) {
            initializeSharpstar().then((sharpstarService) => {
                setSharpstarService(sharpstarService);

                const localCampaign = pushCampaignConfig?.[locale]?.campaignId;
                const storageCampaign = localStorage.getItem('cmp-id'); // cmp-id stands for campaign id

                if (
                    !urlParams.hash &&
                    (!storageCampaign || storageCampaign !== localCampaign)
                ) {
                    localStorage.setItem('cmp-id', localCampaign);
                }
            });
        }
    }, [isPageLoaded, botInfo.visitorId]);

    useEffect(() => {
        if (isPageLoaded) {
            initializeGA().then((gaInstance) => setGA(gaInstance));
        }
    }, [initializeGA, isPageLoaded]);

    useEffect(() => {
        if (isPageLoaded && gameConfig?.buildVersion && analyticsConfig) {
            initializeGameGA(
                gameAnalyticsKey,
                gameAnalyticsSecret,
                gameConfig?.buildVersion,
                analyticsConfig?.dimensions,
                analyticsConfig?.resources
            ).then((gameGAInstance) => setGameGA(gameGAInstance));
        }
    }, [isPageLoaded, analyticsConfig, gameConfig?.buildVersion]);

    useEffect(() => {
        if (offer) {
            const { id, content } = offer;
            const ram = getDeviceRam();
            let newGameConfig, newGaConfig, newAdValues;
            let gaCurrencies, gaItemTypes, build;

            if (id === 0) {
                const { BuildVersion, GaCurrencies, GaItemTypes, ...rest } =
                    content;

                build = BuildVersion;
                gaCurrencies = GaCurrencies?.split(',');
                gaItemTypes = GaItemTypes?.split(',');

                newAdValues = {
                    providers: activeAdProviders,
                    rewardProvider: 'vli',
                    ...adDefaultMap,
                    ...adsSettings,
                };
                newGameConfig = {
                    buildVersion: BuildVersion,
                    buildUrl: `${buildPath}${BuildVersion}/`,
                    main: {
                        DeviceMemory: ram.number,
                        ...rest,
                    },
                };
            } else {
                const {
                    currencies,
                    itemTypes,
                    buildVersion,
                    gameOfferSettings,
                    ...rest
                } = getGameInitValues(content);
                const urlBuildParam = getUrlParamValue('build');
                const adConfig = getAdConfig(content);

                gaCurrencies = currencies;
                gaItemTypes = itemTypes;
                build = urlBuildParam || buildVersion;

                newAdValues = {
                    providers: content['ad:providers']?.split(','),
                    rewardProvider: content['ad:reward-provider'],
                    ...adConfig,
                    ...adsSettings,
                };
                newGameConfig = {
                    buildVersion: build,
                    buildUrl: `${buildPath}${build}/`,
                    popup: gameOfferSettings,
                    main: {
                        DeviceMemory: ram.number,
                        ...rest,
                        ...(urlBuildParam && {
                            AddressablesSettingsPath: `${domain}/${isProd() ? 'game' : 'build'}/${urlBuildParam}`,
                            AddressablesBundlePath: `${domain}/bundle/${urlBuildParam}/WebGL`,
                        }),
                    },
                };
            }

            newGaConfig = {
                dimensions: gaCustomDimensions,
                resources: { currencies: gaCurrencies, itemTypes: gaItemTypes },
            };

            const leadData = filterGameRelatedAttributes(content);

            setLeadExtraData(leadData);
            setAnalyticsConfig(newGaConfig);
            setAdsConfig(newAdValues);
            setGameConfig(newGameConfig);

            Sentry.addBreadcrumb({
                level: 'info',
                message: `game build - ${build.replace(/\./g, '-')}`,
            });
        }
    }, [offer]);

    const checkIsAdBlocked = () => {
        return new Promise((resolve) => {
            checkAdBlockers((adsBlocked) => {
                setAdBlocked(adsBlocked);
                resolve(adsBlocked);
            });
        });
    };

    const handleUrlParams = (rid) => {
        const urlParamsObj = getUrlParams(window.location.href, rid);

        setUrlParams(urlParamsObj);
    };

    const updatePhase = (name) => {
        setPhase(name);
        localStorage.setItem('phase', name);
    };

    const checkPhase = (showPushPhase, showPwaPhase) => {
        const urlHashValue = getHash();
        const phaseFromMap = phaseMap[urlHashValue];

        // If the current URL has a hash, update to this hash phase
        if (phaseFromMap) {
            updatePhase(phaseFromMap);
            return;
        }

        // Retrieve the phase from localStorage or default to 'landing'
        const storedPhase = localStorage.getItem('phase') || 'landing';
        let nextPhase;

        if (storedPhase === 'landing') {
            nextPhase = 'landing';
        } else if (showPushPhase) {
            nextPhase = 'push';
        } else if (showPwaPhase) {
            nextPhase = 'pwa';
        } else {
            nextPhase = 'game';
        }

        updatePhase(nextPhase);
    };

    return (
        <AppContext.Provider
            value={{
                ga,
                gameGA,
                phase,
                botInfo,
                appConfig,
                adBlocked,
                pushCampaignConfig: pushCampaignConfig?.[locale],
                leadExtraData,
                sharpstarService,
                offer,
                setOffer,
                urlParams,
                setUrlParams,
                gameConfig,
                setGameConfig,
                adsConfig,
                setAdsConfig,
                playFabId,
                setPlayFabId,
                checkPhase,
                checkIsAdBlocked,
                updatePhase,
            }}>
            {children}
        </AppContext.Provider>
    );
};

export { AppProvider, AppContext };
