import { environment } from 'App/Helpers';

let config;
const env = environment();

// change this setting to true if you need to build dev build with stage environment settings
const buildStage = false;

const adsConfig = {
    adProvidersSdkLinks: {
        vli: '//nc.pubpowerplatform.io/w/4f705e57-dced-4080-8ea7-b302538a0381.js',
        adinplay:
            '//api.adinplay.com/libs/aiptag/pub/RYM/bacanagol.com/tag.min.js',
        epom: 'https://cdn77.aj2543.bid/3b133b93.js',
    },
    screenBannerMap: {
        HP: ['center-right_hp', 'footer_hp'],
        GO: ['center_result', 'footer_result'],
        Pause: ['center_pause', 'footer_pause'],
        Gameplay: ['footer_gameplay'],
        BonusChest: ['footer_bonus-chest'],
        SkinOpened: ['footer_skin-opened'],
        Customization: ['footer_customization'],
        RewardProgress: ['footer_reward-progress'],
        DailyChallenge: ['footer_daily-challenge'],
        GoldBallOffer: ['footer_offer-gold-ball'],
        DailyBonus: ['footer_offer-daily-rewards'],
        DefaultPushOffer: ['footer_offer-push'],
        Offer: ['footer_offer'],
        Settings: null,
        OfferStatus: null,
        LoadingScreen: null,
        None: null,
    },
};

const baseConfig = {
    buildVersion: '1.7.0.5',
    lang: 'pt',
    isStage: true,
    sourceName: 'Bacanagol',
    gaTrackId: 'G-7QMGB80HXS', // Google Analytics app name: Games-Dev-ALL; stream name: Games Dev
    gameAnalyticsKey: '82028d998441cfbbcd773ef236b10e9c', // link to Game Analytics app - https://tool.gameanalytics.com/game/284703/
    gameAnalyticsSecret: '673cf8464f7a602dd2e022928ff8d6e797c19e08',
    modalsUrl: 'https://enjoythegames.info/wp-json/wp/v2/pages/?slug=',
    terms: 'https://enjoythegames.info/wp-json/wp/v2/pages/?slug=termos-de-uso',
    privacy:
        'https://enjoythegames.info/wp-json/wp/v2/pages/?slug=politica-de-privacidade',
    activeAdProviders: ['dev'],
    adsConfig,
};

const devRid = 160;

const localizationMap = {
    dev: {
        pt: {
            rid: devRid,
            campaignId: 63,
            oneSignalAppId: '879f4b42-ffe0-4fc2-8779-35ef106c32ee', // https://dashboard.onesignal.com/apps/879f4b42-ffe0-4fc2-8779-35ef106c32ee/subscriptions
            safariWebId:
                'web.onesignal.auto.01d22b73-bccb-4a84-9633-69c73285f3f4',
        },
        en: {
            rid: devRid,
            campaignId: 63,
            oneSignalAppId: '879f4b42-ffe0-4fc2-8779-35ef106c32ee', // https://dashboard.onesignal.com/apps/879f4b42-ffe0-4fc2-8779-35ef106c32ee/subscriptions
            safariWebId:
                'web.onesignal.auto.01d22b73-bccb-4a84-9633-69c73285f3f4',
        },
        es: {
            rid: 173,
            campaignId: 82,
            oneSignalAppId: '84983941-4f3b-43f0-9d30-63123123782b', // https://dashboard.onesignal.com/apps/84983941-4f3b-43f0-9d30-63123123782b/subscriptions
            safariWebId:
                'web.onesignal.auto.13169687-b109-4155-bbf4-37fb1a1576f8',
        },
        ro: {
            rid: devRid,
            campaignId: 63,
            oneSignalAppId: '879f4b42-ffe0-4fc2-8779-35ef106c32ee', // https://dashboard.onesignal.com/apps/879f4b42-ffe0-4fc2-8779-35ef106c32ee/subscriptions
            safariWebId:
                'web.onesignal.auto.01d22b73-bccb-4a84-9633-69c73285f3f4',
        },
        bg: {
            rid: devRid,
            campaignId: 63,
            oneSignalAppId: '879f4b42-ffe0-4fc2-8779-35ef106c32ee', // https://dashboard.onesignal.com/apps/879f4b42-ffe0-4fc2-8779-35ef106c32ee/subscriptions
            safariWebId:
                'web.onesignal.auto.01d22b73-bccb-4a84-9633-69c73285f3f4',
        },
        gr: {
            rid: devRid,
            campaignId: 63,
            oneSignalAppId: '879f4b42-ffe0-4fc2-8779-35ef106c32ee', // https://dashboard.onesignal.com/apps/879f4b42-ffe0-4fc2-8779-35ef106c32ee/subscriptions
            safariWebId:
                'web.onesignal.auto.01d22b73-bccb-4a84-9633-69c73285f3f4',
        },
    },
    stage: {
        pt: {
            rid: 165,
            campaignId: 75,
            oneSignalAppId: '37b3686f-42eb-4a91-8ee0-30bea189f12d',
            safariWebId:
                'web.onesignal.auto.2bb5f943-5f36-4c5b-98f3-fab8c5252044',
        },
        en: {
            rid: 165,
            campaignId: 75,
            oneSignalAppId: '37b3686f-42eb-4a91-8ee0-30bea189f12d',
            safariWebId:
                'web.onesignal.auto.2bb5f943-5f36-4c5b-98f3-fab8c5252044',
        },
        es: {
            rid: 174,
            campaignId: 83,
            oneSignalAppId: '72b0f202-1207-4ef8-bea1-a405f49769fc',
            safariWebId:
                'web.onesignal.auto.31ba082c-c81b-42a5-be17-ec59d526e60e',
        },
        ro: {
            rid: 165,
            campaignId: 75,
            oneSignalAppId: '37b3686f-42eb-4a91-8ee0-30bea189f12d',
            safariWebId:
                'web.onesignal.auto.2bb5f943-5f36-4c5b-98f3-fab8c5252044',
        },
        bg: {
            rid: 165,
            campaignId: 75,
            oneSignalAppId: '37b3686f-42eb-4a91-8ee0-30bea189f12d',
            safariWebId:
                'web.onesignal.auto.2bb5f943-5f36-4c5b-98f3-fab8c5252044',
        },
        gr: {
            rid: 165,
            campaignId: 75,
            oneSignalAppId: '37b3686f-42eb-4a91-8ee0-30bea189f12d',
            safariWebId:
                'web.onesignal.auto.2bb5f943-5f36-4c5b-98f3-fab8c5252044',
        },
    },
    prod: {
        pt: {
            rid: 127,
            campaignId: 43,
            oneSignalAppId: '7a89f563-c9a6-42ec-9117-8595e45bde77', // OneSignal prod app name: Games_web BR Production
            safariWebId:
                'web.onesignal.auto.37bbdda8-1be5-416a-8d2a-3d51b0669a43',
        },
        en: {
            rid: 127,
            campaignId: 43,
            oneSignalAppId: '7a89f563-c9a6-42ec-9117-8595e45bde77', // OneSignal prod app name: Games_web BR Production
            safariWebId:
                'web.onesignal.auto.37bbdda8-1be5-416a-8d2a-3d51b0669a43',
        },
        es: {
            rid: 146,
            campaignId: 55,
            oneSignalAppId: '88a70aca-6036-4c33-bbd3-dd730965ca4c',
            safariWebId:
                'web.onesignal.auto.2cee7bb2-7604-4e25-b1d2-cbd521c730a5',
        },
        ro: {
            rid: 127,
            campaignId: 43,
            oneSignalAppId: '7a89f563-c9a6-42ec-9117-8595e45bde77', // OneSignal prod app name: Games_web BR Production
            safariWebId:
                'web.onesignal.auto.37bbdda8-1be5-416a-8d2a-3d51b0669a43',
        },
        bg: {
            rid: 127,
            campaignId: 43,
            oneSignalAppId: '7a89f563-c9a6-42ec-9117-8595e45bde77', // OneSignal prod app name: Games_web BR Production
            safariWebId:
                'web.onesignal.auto.37bbdda8-1be5-416a-8d2a-3d51b0669a43',
        },
        gr: {
            rid: 127,
            campaignId: 43,
            oneSignalAppId: '7a89f563-c9a6-42ec-9117-8595e45bde77', // OneSignal prod app name: Games_web BR Production
            safariWebId:
                'web.onesignal.auto.37bbdda8-1be5-416a-8d2a-3d51b0669a43',
        },
    },
};

// localhost works only for landing phase
const local = {
    ...baseConfig,
    rid: 115,
    campaignId: 38,
    source: `${baseConfig.sourceName} Local`,
    sharpstarApiUrl: 'https://dcstage.sharpstar.pro/',
    domain: 'http://127.0.0.1:8080',
    cookiesDomain: '127.0.0.1:8080',
};

const dev = {
    ...baseConfig,
    source: `${baseConfig.sourceName} Dev`,
    sharpstarApiUrl: 'https://dcstage.sharpstar.pro/',
    domain: 'https://dev.bacanagol.com',
    cookiesDomain: 'dev.bacanagol.com',
    buildPath: 'https://dev.bacanagol.com/build/',
    pushCampaignConfig: { ...localizationMap.dev },
};

const stage = {
    ...baseConfig,
    source: `${baseConfig.sourceName} Stage`,
    sharpstarApiUrl: 'https://dcstage.sharpstar.pro/',
    domain: 'https://stage.bacanagol.com',
    cookiesDomain: 'stage.bacanagol.com',
    buildPath: 'https://stage.bacanagol.com/build/',
    pushCampaignConfig: { ...localizationMap.stage },
    activeAdProviders: ['epom', 'vli'],
};

const prod = {
    ...baseConfig,
    isStage: false,
    source: `${baseConfig.sourceName} Prod`,
    sharpstarApiUrl: 'https://dc.sharpstar.pro/',
    domain: 'https://bacanagol.com',
    cookiesDomain: 'bacanagol.com',
    buildPath: 'https://bacanagol.com/game/',
    pushCampaignConfig: { ...localizationMap.prod },
    gaTrackId: 'G-EYRMRLHD2R', // Google Analytics app name: Football-BR
    gameAnalyticsKey: 'a54d03eeebbf057b8b486126e7e395a5', // link to Game Analytics app - https://tool.gameanalytics.com/game/266513/
    gameAnalyticsSecret: '98131f4980849c8fa568756d53984384d0d5565a',
    activeAdProviders: ['epom', 'vli'],
};

if (env === 'production') {
    config = prod;
} else if (env === 'development') {
    buildStage ? (config = stage) : (config = dev);
} else {
    config = local;
}

export const languages = ['pt', 'en', 'es', 'ro', 'bg', 'gr'];

export const gaCustomDimensions = {
    values1: ['push_default', 'push_granted', 'push_denied'],
    values2: ['pwa_installed', 'pwa_not_installed', 'pwa_not_supported'],
    values3: [
        'Low_RAM',
        'Medium_Low_RAM',
        'Medium_RAM',
        'High_RAM',
        'Very_High_RAM',
        'Ultra_High_RAM',
        'Unknown_RAM',
    ],
};

export const adDefaultMap = {
    sideBanners: {
        'left-narrow': 'empty',
        'left-wide': 'empty',
        'right-narrow': 'empty',
        'right-wide': 'empty',
    },
    banners: {
        'center-right_hp': 'epom:494a2245e23b596b356881d5ea0e63bf',
        'center_pause': 'vli:pw_19874',
        'center_result': 'vli:pw_19875',
        'footer_bonus-chest': 'vli:pw_19135',
        'footer_customization': 'vli:pw_19134',
        'footer_daily-challenge': 'vli:pw_33844',
        'footer_gameplay': 'vli:pw_19132',
        'footer_hp': 'vli:pw_19131',
        'footer_offer': 'vli:pw_33843',
        'footer_pause': 'vli:pw_19873',
        'footer_result': 'epom:3835377c68bac521cc4869ef2e768a48',
        'footer_reward-progress': 'vli:pw_19137',
        'footer_skin-opened': 'vli:pw_19136',
        'footer_offer-gold-ball': 'vli:pw_46311',
        'footer_offer-push': 'vli:pw_46312',
        'footer_offer-daily-rewards': 'vli:pw_46313',
    },
    rewards: {
        Plus500Coins: 'pw_33449',
        Plus100Coins: 'pw_33450',
        Plus1Bag: 'pw_33451',
        GetThirdKey: 'pw_33452',
        CollectAndSkipLevel: 'pw_33453',
        MultiplyCoins: 'pw_33454',
        ExtraProgress: 'pw_33455',
        SkipLevel: 'pw_41631',
        ReplayLevel: 'pw_41804',
        StarterOfferReward: 'pw_46323',
        ResBoosterReward: 'pw_46324',
        BattlePassStar: 'pw_46325',
        DailyRewardX2: 'pw_46326',
        GetEnergyCocktail: 'pw_46327',
    },
};

export default config;
