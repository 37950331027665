import { useEffect, useState } from 'react';

import FingerprintJS from '@fingerprintjs/fingerprintjs';

export const analyticsLabels = {
    BotSignature: 'User-Agent contains bot signature',
    Headless: 'Detected Headless Browser',
    NavProps: 'Suspicious navigator properties',
    PerfAPI: 'Unusual Performance API behavior',
    NoInteraction: 'No real user interaction detected',
};

const botPatterns = [
    /bot/i,
    /crawl/i,
    /spider/i,
    /slurp/i,
    /mediapartners/i,
    /facebook/i,
    /baiduspider/i,
    /yandex/i,
    /duckduckbot/i,
    /bingbot/i,
    /googlebot/i,
];

const useDetectBot = () => {
    const [isBot, setIsBot] = useState(null);
    const [visitorId, setVisitorId] = useState(null);
    const [failedChecks, setFailedChecks] = useState([]);

    useEffect(() => {
        const detectBot = async () => {
            // Prevent execution if Puppeteer prebuild mode is active
            if (window.__PUPPETEER_PREBUILD_MODE__) {
                setIsBot(false);
                setFailedChecks([]);
                return;
            }

            const failedChecksList = [];

            // Load FingerprintJS
            const fp = await FingerprintJS.load();
            const { visitorId, components, confidence } = await fp.get();

            setVisitorId(visitorId);

            // Check User-Agent String (Detects Crawlers)
            const isBotByUserAgent = () => {
                const isBot = botPatterns.some((pattern) =>
                    pattern.test(navigator.userAgent)
                );

                if (isBot) failedChecksList.push('BotSignature');
                return isBot;
            };

            // Detect Headless Browsers (Puppeteer, Selenium)
            const isHeadlessBrowser = () => {
                const userAgent = navigator.userAgent.toLowerCase();

                const isHeadless =
                    navigator.webdriver ||
                    /headlesschrome/.test(userAgent) ||
                    /headlessfirefox/.test(userAgent) ||
                    /phantomjs/.test(userAgent) ||
                    (!window.outerWidth && !window.outerHeight) ||
                    window.screen.width === 0 ||
                    window.screen.height === 0;

                if (isHeadless) failedChecksList.push('Headless');
                return isHeadless;
            };

            // Detect Suspicious `navigator` Properties
            const isNavigatorSuspicious = () => {
                const missingLanguages =
                    !navigator.languages || navigator.languages.length === 0;
                const missingHardwareConcurrency =
                    !navigator.hardwareConcurrency ||
                    navigator.hardwareConcurrency < 1;
                const isWebDriver = navigator.webdriver === true;

                const isSuspicious =
                    missingLanguages ||
                    missingHardwareConcurrency ||
                    isWebDriver;

                if (isSuspicious) failedChecksList.push('NavProps');
                return isSuspicious;
            };

            // Detect Suspicious `performance` API Activity
            const isPerformanceSuspicious = () => {
                const isSuspicious =
                    !performance.getEntriesByType('navigation').length;

                if (isSuspicious) failedChecksList.push('PerfAPI');
                return isSuspicious;
            };

            // Detect Missing Fonts (Bots often have no system fonts)
            const isFontsSuspicious = () => {
                const fontsAvailable = components.fonts?.value?.length > 0;
                const isSuspicious = !fontsAvailable;

                if (isSuspicious) failedChecksList.push('Fonts');
                return isSuspicious;
            };

            // Detect Audio Anomalies (Bots often have no Audio Context)
            const isAudioSuspicious = () => {
                const hasAudio = components.audio?.value !== 0;
                const isSuspicious = !hasAudio;

                if (isSuspicious) failedChecksList.push('Audio');
                return isSuspicious;
            };

            // Check Low Confidence Score (Bots often have low confidence scores)
            const getConfidenceScore = () => {
                const confidenceScore = confidence.score;

                failedChecksList.push(`Confidence${confidenceScore}`);

                return confidenceScore;
            };

            const botUserAgent = isBotByUserAgent();
            const headlessBrowser = isHeadlessBrowser();
            const suspiciousNavigator = isNavigatorSuspicious();
            const suspiciousPerformance = isPerformanceSuspicious();
            const suspiciousFonts = isFontsSuspicious();
            const suspiciousAudio = isAudioSuspicious();
            const confidenceScore = getConfidenceScore();

            const totalFailedChecks = [
                botUserAgent,
                headlessBrowser,
                suspiciousNavigator,
                suspiciousPerformance,
                suspiciousFonts,
                suspiciousAudio,
                confidenceScore,
            ].filter(Boolean).length;

            setFailedChecks(failedChecksList);
            setIsBot(totalFailedChecks >= 2);
        };

        detectBot();
    }, []);

    return { visitorId, isBot, failedChecks };
};

export default useDetectBot;
