import React, { useContext, useState } from 'react';

import { useOutsideClick } from 'App/Game/hooks';

import arrowBottom from '../../assets/arrow-down.svg';
import arrowTop from '../../assets/arrow-top.svg';
import arrowTopWhite from '../../assets/arrowWhite.svg';
import arrowBottomWhite from '../../assets/arrowWhitebotton.svg';
import { AppContext, LanguageContext } from '../../providers';

import './styles.scss';

const languageMenu = [
    { Language: 'Português', value: 'pt' },
    { Language: 'English', value: 'en' },
    { Language: 'Español', value: 'es' },
    { Language: 'Română', value: 'ro' },
    { Language: 'Български', value: 'bg' },
    { Language: 'Ελληνικά', value: 'gr' },
];

const LanguageSelector = ({ backgroundSelector = 'transparent' }) => {
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);

    const { phase } = useContext(AppContext);
    const { locale, changeLanguage } = useContext(LanguageContext);
    const outsideRef = useOutsideClick(() => setIsDropdownVisible(false));

    const isPushPhase = phase === 'push';
    const arrowUp = isPushPhase ? arrowTopWhite : arrowTop;
    const arrowDown = isPushPhase ? arrowBottomWhite : arrowBottom;

    const handleChangeLanguage = (value) => {
        changeLanguage(value, phase === 'landing');
        setIsDropdownVisible(false);
    };

    return (
        <div className='language-dropdown' ref={outsideRef}>
            <div
                tabIndex={0}
                role={'button'}
                className={'language'}
                aria-label={'Select language'}
                aria-expanded={isDropdownVisible}
                style={{ backgroundColor: backgroundSelector }}
                onClick={() => setIsDropdownVisible(!isDropdownVisible)}
                onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                        setIsDropdownVisible(!isDropdownVisible);
                    }
                }}>
                <div className={'language-info'}>
                    <span
                        style={{
                            color: isPushPhase ? 'white' : '#043192',
                        }}>
                        {locale}
                    </span>
                    <img
                        className={'language-arrow'}
                        src={isDropdownVisible ? arrowUp : arrowDown}
                        width={16}
                        height={12}
                        alt={'arrow'}
                    />
                </div>
            </div>

            <div
                className={`languages-box ${isDropdownVisible ? 'visible' : ''}`}>
                <ul className={isDropdownVisible ? 'visible' : 'hidden'}>
                    {languageMenu.map(({ Language, value }) => (
                        <li key={value}>
                            <button
                                data-lang={value}
                                disabled={locale === value}
                                className={'languages-box_button'}
                                onClick={() => handleChangeLanguage(value)}>
                                <span
                                    className={
                                        locale === value ? 'selected' : ''
                                    }>
                                    {Language}
                                </span>
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
            {isDropdownVisible && (
                <div
                    className={'languages-filter'}
                    onClick={() => setIsDropdownVisible(false)}
                />
            )}
        </div>
    );
};

export default LanguageSelector;
