import { lazy, Suspense, useContext, useEffect } from 'react';

import * as Sentry from '@sentry/react';
import { fetchGameConfig } from 'App/Game/helpers';
import BubbleLoader from 'App/React/_UI/BubbleLoader';

import { getGameDefaults } from './helpers';
import Landing from './pages/Landing';
import { AppContext, PushContext, PwaContext } from './providers';

import './main.scss';

const DynamicPhaseRenderer = lazy(
    () => import('./components/DynamicPhaseRenderer')
);
const SendAppAnalytics = lazy(() => import('./components/SendAppAnalytics'));

const App = () => {
    const { pushStatus, showPushPhase, pushPhaseViews, setPushPromptTotal } =
        useContext(PushContext);
    const { pwaStatus, showPwaPhase, setPwaPhaseTotal } =
        useContext(PwaContext);
    const {
        ga,
        gameGA,
        phase,
        checkPhase,
        setOffer,
        appConfig,
        sharpstarService,
        urlParams: { rid },
        botInfo: { isBot, failedChecks, visitorId },
    } = useContext(AppContext);

    useEffect(() => {
        checkPhase(showPushPhase, showPwaPhase);
    }, []);

    useEffect(() => {
        const fetchOffers = async () => {
            if (!sharpstarService) return;

            try {
                const response = await sharpstarService.getOffers({
                    isLead: 0,
                    hash: undefined,
                    botFailedChecks: failedChecks,
                    visitorId,
                    isBot,
                    rid,
                });
                const { id, content } = response.offers[0];

                setOffer({ id, content });
                setPushPromptTotal(Number(content['push-prompt-total'] || 0));
                setPwaPhaseTotal(Number(content['pwa-prompt-total'] || 0));

                localStorage.setItem('buildVersion', content['build-version']);
                localStorage.setItem('offerId', id);
            } catch (e) {
                Sentry.captureException(e);

                setPushPromptTotal(0);
                setPwaPhaseTotal(0);

                try {
                    const remoteConfig = await fetchGameConfig(
                        appConfig.domain
                    );
                    setOffer({ id: 0, content: remoteConfig });

                    Sentry.withScope((scope) => {
                        scope.setLevel('info');
                        Sentry.captureException(
                            new Error('AWS remote config was fetched')
                        );
                    });
                } catch (configError) {
                    Sentry.captureException(configError);

                    const defaults = getGameDefaults(appConfig);
                    setOffer({ id: 0, content: defaults });
                }
            }
        };

        fetchOffers();
    }, [sharpstarService]);

    return (
        <>
            {phase === 'landing' ? (
                <Landing
                    showPwaPhase={showPwaPhase}
                    showPushPhase={showPushPhase}
                />
            ) : (
                <Suspense
                    fallback={
                        <BubbleLoader
                            loaderColor={'#09328d'}
                            backgroundColor={'#b7d6fe'}
                        />
                    }>
                    {!!gameGA && !!ga && <DynamicPhaseRenderer />}
                </Suspense>
            )}
            {!!gameGA && !!ga && (
                <Suspense fallback={<></>}>
                    <SendAppAnalytics
                        ga={ga}
                        gameGA={gameGA}
                        pushPhaseViews={pushPhaseViews}
                        pwaStatus={pwaStatus}
                        pushStatus={pushStatus}
                    />
                </Suspense>
            )}
        </>
    );
};

export default App;
