import * as Sentry from '@sentry/react';

import { RAM_CATEGORIES, RAM_THRESHOLDS } from '../constants';

export const getUrlParams = (url, rid = null) => {
    const requiredParams = {
        refid1: '0',
        refid2: '0',
        refid3: '0',
        hash: undefined,
        rid,
    };

    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.search);
    const result = {};

    Object.keys(requiredParams).forEach((param) => {
        const paramValue = params.get(param);

        // update requiredParams from url values
        if (paramValue !== null) {
            result[param] = paramValue;
        } else {
            result[param] = requiredParams[param];
        }

        // get, update localStorage values if necessary
        if (
            result[param] !== '0' &&
            result[param] !== null &&
            result[param] !== undefined
        ) {
            localStorage.setItem(param, result[param].toString());
        } else if (localStorage.getItem(param)) {
            result[param] = localStorage.getItem(param);
        }
    });

    return result;
};

const normalizeKey = (key) => {
    const normalizedKey = key
        .replace(/^(game-popup-|game-)/, '')
        .replace(/-([a-z])/g, (_, letter) => letter.toUpperCase());

    return normalizedKey.charAt(0).toUpperCase() + normalizedKey.slice(1);
};

/**
 * Filter an input object for keys starting with "game-", convert those keys from kebab-case to PascalCase, and return a new object with the converted keys and their corresponding values
 *
 * @param apiConfig - dynamic config object from sharpstar api getOffers
 *
 * return {[key]: value}
 */
export const getGameInitValues = (apiConfig) => {
    return Object.entries(apiConfig).reduce((result, [key, value]) => {
        if (key.startsWith('game-popup-')) {
            const normalizedPopupKey = normalizeKey(key);

            result['gameOfferSettings'] = {
                ...result['gameOfferSettings'],
                [normalizedPopupKey]: value,
            };
        } else if (key.startsWith('game-')) {
            const normalizedKey = normalizeKey(key);

            result[normalizedKey] = value;
        } else if (key === 'build-version') {
            result['buildVersion'] = value;
        } else if (key === 'ga-currencies') {
            result['currencies'] = value?.split(',');
        } else if (key === 'ga-item-types') {
            result['itemTypes'] = value?.split(',');
        }

        return result;
    }, {});
};

/**
 * Filters game related "key: value" pairs from sharpstar config to set as sharpstar lead attributes
 *
 * return {[string]: string}
 */
export const filterGameRelatedAttributes = (inputObj) => {
    const outputObj = {};

    for (const key in inputObj) {
        if (
            (key.startsWith('game-') || key.startsWith('build-')) &&
            !key.includes('addressables')
        ) {
            outputObj[key] = inputObj[key];
        }
    }

    return outputObj;
};

export const localStorageValueReset = (key, value) => {
    const localStorageValue = localStorage.getItem(key);

    !localStorageValue && localStorage.setItem(key, value);

    return localStorageValue;
};

export function getDeviceRam() {
    const deviceMemory = navigator.deviceMemory || 0;
    let deviceMemoryString;

    if (deviceMemory < RAM_THRESHOLDS.LOW) {
        deviceMemoryString = RAM_CATEGORIES.LOW;
    } else if (deviceMemory < RAM_THRESHOLDS.MEDIUM_LOW) {
        deviceMemoryString = RAM_CATEGORIES.MEDIUM_LOW;
    } else if (deviceMemory < RAM_THRESHOLDS.MEDIUM) {
        deviceMemoryString = RAM_CATEGORIES.MEDIUM;
    } else if (deviceMemory < RAM_THRESHOLDS.HIGH) {
        deviceMemoryString = RAM_CATEGORIES.HIGH;
    } else if (deviceMemory < RAM_THRESHOLDS.VERY_HIGH) {
        deviceMemoryString = RAM_CATEGORIES.VERY_HIGH;
    } else {
        deviceMemoryString = RAM_CATEGORIES.ULTRA_HIGH;
    }

    return {
        string: deviceMemoryString,
        number: deviceMemory,
    };
}

export function getPushDimension(pushStatus) {
    if (pushStatus === 'default') {
        return 'push_default';
    }
    if (pushStatus === 'granted') {
        return 'push_granted';
    }
    if (pushStatus === 'denied') {
        return 'push_denied';
    }

    return undefined;
}

export function getPwaDimension(pwaStatus) {
    if (pwaStatus === 'Installed') {
        return 'pwa_installed';
    }
    if (pwaStatus === 'NotInstalled') {
        return 'pwa_not_installed';
    }
    if (pwaStatus === 'NotSupported') {
        return 'pwa_not_supported';
    }

    return undefined;
}

export const fetchGameConfig = async (domain) => {
    try {
        const response = await fetch(`${domain}/configs/GameConfig.json`);

        if (!response.ok) {
            Sentry.captureException(
                new Error(
                    `Failed to load GameConfig.json: ${response.statusText}`
                )
            );
        }

        return await response.json();
    } catch (error) {
        Sentry.captureException(error);
    }
};
