import React from 'react';

import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';

import App from './App';
import config from './config';
import {
    AppProvider,
    LanguageProvider,
    PushNotificationProvider,
    PwaProvider,
} from './providers';

const root = ReactDOM.createRoot(document.getElementById('root'), {
    onUncaughtError: Sentry.reactErrorHandler((error, errorInfo) => {
        console.warn('Uncaught error', error, errorInfo.componentStack);
    }),
    onRecoverableError: Sentry.reactErrorHandler(),
});

root.render(
    <LanguageProvider>
        <PushNotificationProvider>
            <PwaProvider>
                <AppProvider appConfig={config}>
                    <App />
                </AppProvider>
            </PwaProvider>
        </PushNotificationProvider>
    </LanguageProvider>
);
