export const REWARD_WAS_CLOSED = 0;
export const REWARD_RECEIVED = 1;
export const REWARD_FAILED = 2;
export const DEV_MODE_REWARD_DELAY_MS = 100;
export const AD_UPDATE_CHECK_DELAY_MS = 1850;
export const MIN_SIDE_CONTAINER_WIDTH_NARROW = 190;
export const MAX_SIDE_CONTAINER_WIDTH_NARROW = 370;
export const MIN_WINDOW_HEIGHT_FOR_SIDE_BANNERS = 620;
export const EPOM_FETCH_URL = 'https://aj2543.bid/bebfc-api-v3';
export const NO_GAME_SCREEN_MESSAGE =
    'Unavailable game screen when show ad banner: ';
