import * as Sentry from '@sentry/react';

/**
 * Initializes Sentry for error tracking and performance monitoring.
 *
 * @param enabled {boolean}- Flag to enable or disable Sentry default true.
 * @param location {string} - Current location URL to allow in Sentry.
 * @param environment {string} - Environment string (e.g., production,local,development staging).
 * @param campaignId {string} - Campaign identifier, used as a tag in Sentry.
 * @param source {string} - Source of the event, used as a tag in Sentry.
 * @param refid3 {string} - The third referral ID we get from URL is used as a tag in Sentry.
 * @param refid2 {string} - The second referral ID we get from the URL is used as a tag in Sentry.
 * @param refid1 {string} - The first referral ID we get from the URL is used as a tag in Sentry.
 * @param id {string} - User identifier to associate with Sentry events.
 * @param performanceRate {number} - Sample rate for performance monitoring (optional).
 */

const initReactSentry = ({
    environment,
    campaignId,
    location,
    source,
    refid3,
    refid2,
    refid1,
    id,
    performanceRate,
    enabled = true,
}) => {
    if (enabled) {
        Sentry.init({
            dsn: 'https://5b86359019bf4330803e3e33b36d936e@sentry.io/1415478',
            environment,
            integrations: [
                //Adds context lines of code around the location where the error occurred.Defaults to 7.
                Sentry.contextLinesIntegration(),
                //Logs HTTP requests and responses to track which requests may have caused an error.
                Sentry.extraErrorDataIntegration(),
                //Enables performance tracing in the browser.
                Sentry.browserTracingIntegration(),
            ],
            ...(performanceRate && { tracesSampleRate: performanceRate }),
            allowUrls: [
                /https?:\/\/((udata|lpdev)\.)?sharpstar\.(pro|dev)/,
                location,
            ],
            initialScope: {
                tags: {
                    source,
                    refid3,
                    campaign_id: campaignId,
                    ...(refid2 !== '0' && { refid2 }),
                    ...(refid1 !== '0' && { refid1 }),
                },
                ...(id && { user: { id } }),
            },
            denyUrls: [
                /https:\/\/cdn\.doubleverify\.com\/dv-measurements\d+\.js/,
                /^https:\/\/cadmus\.script\.ac\/[a-zA-Z0-9]+\/[a-zA-Z0-9]+\.js$/,
                'https://c.amazon-adsystem.com/aax2/apstag.js',
                'https://static.cloudflareinsights.com/',
                'https://securepubads.g.doubleclick.net/',
                'https://imasdk.googleapis.com/',
                'https://cdn.flashtalking.com/',
                'https://cdn.doubleverify.com/',
                'https://www.googletagmanager.com/gtag/js',
                // Chrome extensions
                'chrome-extension://haldlgldplgnggkjaafhelgiaglafanh/admin.js',
                /extensions\//i,
                /^chrome:\/\//i,
                /^chrome-extension:\/\//i,
            ],
            beforeSend(event, hint) {
                const originalException = hint.originalException;
                event.extra = event.extra || {};

                // Ignore aborted requests
                if (
                    originalException &&
                    originalException.name === 'AbortError'
                ) {
                    return null;
                }

                if (originalException != null) {
                    // Assign enumerable error properties to extras
                    try {
                        const keys = Object.keys(originalException);
                        if (keys.length) {
                            event.extra.errorProperties = {};
                            keys.forEach((key) => {
                                event.extra.errorProperties[key] =
                                    originalException[key];
                            });
                        }
                    } catch (error) {
                        console.warn(
                            '[sentry] Failed to assign enumerable error properties to extras',
                            error
                        );
                    }
                }

                let exceptions = event.exception;
                if (exceptions) {
                    let values =
                        exceptions.values[exceptions.values.length - 1];
                    //check if exceptions has values
                    if (values) {
                        let stacktrace = values.stacktrace;
                        //check if exception has values
                        if (stacktrace) {
                            let frames =
                                stacktrace.frames[stacktrace.frames.length - 1];
                            //check if stacktrace has frames
                            if (frames) {
                                let fileName = frames.filename;
                                //check if stacktrace has file name
                                if (fileName && event.tags) {
                                    // error_source tag to sentry event
                                    event.tags.error_source = fileName;
                                }
                            }
                        }
                    }
                }
                return event;
            },
        });
    }
};

export default initReactSentry;
