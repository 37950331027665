import React from 'react';

import LazyImg from 'App/React/_UI/LazyImg';

import playIcon from '../../../../assets/btn-play-icon.svg';
import advantageBox from '../../../../assets/hero_advantage-box.svg';
import ball1920 from '../../../../assets/hero_ball-1920.png';
import freeLogoIcon from '../../../../assets/hero_free-icon.png';
import freeIcon from '../../../../assets/hero_free.png';
import graphics3DIcon from '../../../../assets/hero_graphics3D.png';
import logo from '../../../../assets/hero_logo.svg';
import permissionIcon from '../../../../assets/hero_permission-icon.png';
import footbolist1920 from '../../../../assets/hero_player-1920.png';
import registrationIcon from '../../../../assets/hero_registration-icon.png';
import simpleIcon from '../../../../assets/hero_simple.png';
import skinsIcon from '../../../../assets/hero_skins.png';
import LanguageSelector from '../../../../components/LanguageSelector';
import polyglot from '../../../../localization';
import './styles.scss';

const Hero = ({ lang, onBtnClick }) => {
    return (
        <section className='hero'>
            <div className='hero_container'>
                <div className='hero_main'>
                    <div className='hero_main-ball hero_main-img'>
                        <LazyImg
                            url={ball1920}
                            width={326}
                            height={500}
                            alt={polyglot.t(`${lang}.howPlay.alt.step1`)}
                        />
                    </div>
                    <div className='hero_logo-container wrapper container'>
                        <div className='hero_logo'>
                            <img
                                src={logo}
                                width={155}
                                height={123}
                                alt={polyglot.t(`${lang}.hero.alt.logo`)}
                            />
                        </div>
                        <LanguageSelector />
                    </div>
                    <div className='hero_main-box'>
                        <div className='hero_main-footbolist hero_main-img'>
                            <LazyImg
                                url={footbolist1920}
                                width={371}
                                height={761}
                                alt={polyglot.t(`${lang}.howPlay.alt.step1`)}
                            />
                        </div>
                        <h1 className='hero_main-title'>
                            {polyglot.t(`${lang}.hero.title`)}
                        </h1>
                        <p className='hero_main-subtitle'>
                            {polyglot.t(`${lang}.hero.subtitle`)}
                        </p>
                        <button
                            className='main-btn'
                            onClick={() => onBtnClick()}>
                            {polyglot.t(`${lang}.button`)}
                            <img
                                src={playIcon}
                                width={20}
                                height={23}
                                alt={polyglot.t(`${lang}.hero.alt.btn_play`)}
                            />
                        </button>
                        <div className='hero_policies'>
                            <div className='hero_policy'>
                                <div className='hero_policy-img'>
                                    <img
                                        src={freeLogoIcon}
                                        width={83}
                                        height={83}
                                        alt={polyglot.t(
                                            `${lang}.hero.alt.wallet`
                                        )}
                                    />
                                </div>
                                <p className='hero_policy-text'>
                                    <em>{polyglot.t(`${lang}.hero.free`)}</em>
                                </p>
                            </div>
                            <div className='hero_policy'>
                                <div className='hero_policy-img'>
                                    <img
                                        src={registrationIcon}
                                        width={83}
                                        height={83}
                                        alt={polyglot.t(
                                            `${lang}.hero.alt.human`
                                        )}
                                    />
                                </div>
                                <p className='hero_policy-text'>
                                    <em>
                                        {polyglot.t(
                                            `${lang}.hero.noRegistration`
                                        )}
                                    </em>
                                </p>
                            </div>
                            <div className='hero_policy'>
                                <div className='hero_policy-img'>
                                    <img
                                        src={permissionIcon}
                                        width={83}
                                        height={83}
                                        alt={polyglot.t(`${lang}.hero.alt.key`)}
                                    />
                                </div>
                                <p className='hero_policy-text'>
                                    <em>
                                        {polyglot.t(
                                            `${lang}.hero.noPermission`
                                        )}
                                    </em>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <ul className='hero_advantages'>
                        <li className='hero_advantage-item'>
                            <img
                                className='hero_advantage-icon'
                                width={326}
                                height={180}
                                src={freeIcon}
                                alt={polyglot.t(`${lang}.hero.alt.lock`)}
                            />
                            <img
                                className='hero_advantage-box'
                                width={369}
                                height={312}
                                src={advantageBox}
                                alt={polyglot.t(`${lang}.hero.alt.white_back`)}
                            />
                            <p className='hero_advantage-text'>
                                {polyglot.t(`${lang}.advantages.free`)}
                            </p>
                        </li>
                        <li className='hero_advantage-item'>
                            <img
                                className='hero_advantage-icon'
                                width={326}
                                height={180}
                                src={skinsIcon}
                                alt={polyglot.t(`${lang}.hero.alt.skins`)}
                            />
                            <img
                                className='hero_advantage-box'
                                width={369}
                                height={312}
                                src={advantageBox}
                                alt={polyglot.t(`${lang}.hero.alt.white_back`)}
                            />
                            <p className='hero_advantage-text hero_advantage-people'>
                                {polyglot.t(`${lang}.advantages.skins`)}
                            </p>
                        </li>
                        <li className='hero_advantage-item'>
                            <img
                                className='hero_advantage-icon'
                                width={326}
                                height={180}
                                src={graphics3DIcon}
                                alt={polyglot.t(`${lang}.hero.alt.3d`)}
                            />
                            <img
                                className='hero_advantage-box'
                                width={369}
                                height={312}
                                src={advantageBox}
                                alt={polyglot.t(`${lang}.hero.alt.white_back`)}
                            />
                            <p className='hero_advantage-text'>
                                {polyglot.t(`${lang}.advantages.graphics3D`)}
                            </p>
                        </li>
                        <li className='hero_advantage-item'>
                            <img
                                className='hero_advantage-icon'
                                width={326}
                                height={180}
                                src={simpleIcon}
                                alt={polyglot.t(`${lang}.hero.alt.puzzle`)}
                            />
                            <img
                                className='hero_advantage-box'
                                width={369}
                                height={312}
                                src={advantageBox}
                                alt={polyglot.t(`${lang}.hero.alt.white_back`)}
                            />
                            <p className='hero_advantage-text'>
                                {polyglot.t(`${lang}.advantages.simple`)}
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default Hero;
