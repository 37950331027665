export const SUBSCRIBE_REQUEST_NUMBER = 3; // NUMBER OF TIMES A USER IS ASKED TO SUBSCRIBE TO PUSH NOTIFICATION
export const MILLISECONDS_IN_A_SECOND = 1000;
export const DECIMAL_PLACES = 2;
export const RAM_THRESHOLDS = {
    LOW: 2,
    MEDIUM_LOW: 4,
    MEDIUM: 6,
    HIGH: 8,
    VERY_HIGH: 12,
};

export const RAM_CATEGORIES = {
    LOW: 'Low_RAM',
    MEDIUM_LOW: 'Medium_Low_RAM',
    MEDIUM: 'Medium_RAM',
    HIGH: 'High_RAM',
    VERY_HIGH: 'Very_High_RAM',
    ULTRA_HIGH: 'Ultra_High_RAM',
};
